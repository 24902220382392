import React from 'react';
import {
    Route,
    Redirect,
    Switch
} from 'react-router-dom';

import ForSale from '../pages/ForSale';
import Services from '../pages/Services';
import ComingSoon from '../pages/ComingSoon';
import About from '../pages/About';

const AppRoutes = () => (
    <Switch>
        <Route exact path="/" component={About}/>
        <Route path="/forsale/:search(rifle|pistol|revolver|shotgun)?" component={ForSale}/>
        <Route path="/services/:service?" component={Services}/>
        <Route exact path="/news" component={ComingSoon}/>
        <Route exact path="/resources" component={ComingSoon}/>
        <Route exact path="/policies" component={ComingSoon}/>
        <Route exact path="/legislation" component={ComingSoon}/>
        <Route exact path="/pa-news" component={ComingSoon}/>
        <Route exact path="/national-news" component={ComingSoon}/>
        <Redirect to='/'/>
    </Switch>
);

export default AppRoutes;


