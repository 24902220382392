import React from 'react';
import PropTypes from 'prop-types';
import PromoGraphic from './PromoGraphic';
import ProductDetails from './ProductDetails';
import {withStyles} from '@material-ui/core/styles';

class Product extends React.Component {
    render() {
        const {classes, gun}  = this.props;
        return (
            <div className={classes.container}>
                <div className={classes.image}>
                    <PromoGraphic title={gun.name} price={gun.price} picture={gun.image} status={gun.status}/>
                </div>
                <ProductDetails gun={gun}/>
            </div>
        );
    }
}
Product.propTypes = {
    gun: PropTypes.object.isRequired
}

const styles = (theme) => ({
    container:{
        maxWidth: '100%',
        //width: 900, 
        textAlign: 'left', 
        display: 'flex', 
        padding: 40,
        alignItems: 'flex-start',
        [theme.breakpoints.down('sm')]:{
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            padding: 20
        }
    },
    image:{
        flex: '1 1 0px',
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]:{
            display: 'block',
            maxWidth: '100%'
            //width: '100%'
        }
    }
})
export default withStyles(styles)(Product);