import React from 'react';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';

class Services extends React.Component {
  render() {
    const {classes} = this.props;
    return (
        <div style={{padding: 40, maxWidth: 600, margin: '0px auto'}}>
          <Typography variant={'h6'} color={'primary'} paragraph className={classes.title}>Consignment</Typography>
            <Typography variant={'body1'} color={'primary'} className={classes.paragraph} paragraph>We can accept your firearms for display and sale in our shop on a consignment basis. You keep 90% of the sale price! Consignments are also photographed and listed for sale on our website - no extra charge.</Typography>
          
          <Typography variant={'h6'} color={'primary'} paragraph className={classes.title}>Transfers</Typography>
            <Typography variant={'body1'} color={'primary'} paragraph className={classes.paragraph}>We handle the registrations for guns sold by Guns Priced Right and for online sales coming from FFLs. We also transfer registration for firearms sold as part of a private transaction between parties.</Typography>
            <Typography variant={'body1'} color={'primary'} paragraph className={classes.paragraph}>We DO NOT accept transfers from gun dealers who list guns on classified websites (e.g., Gun Broker, Guns America, Armlist, etc.). If in doubt or you have any reservations about accepting a firearm from one of these types of websites, please call us (412-466-5199) BEFORE you purchase.</Typography>
            <Typography variant={'body1'} color={'primary'} paragraph className={classes.paragraph}>Person to person (private sale) transfers are $30. The registration fee for transfers coming from an FFL is $25.</Typography>
          
          <Typography variant={'h6'} color={'primary'} paragraph className={classes.title}>Photography</Typography>
            <Typography variant={'body1'} color={'primary'} className={classes.paragraph} paragraph>Do you need a firearms collection photographed for insurances purposes? We can print photos for albums and/or burn the collection on CD. Custom photography packages are designed to meet any budget. Just give us a call and let us know what you need.</Typography>
        </div>
    );
  }
}

const styles = (themes) => ({
  paragraph:{
    fontWeight: 300,
    lineHeight: 1.5
  }
});
export default withStyles(styles)(Services);

        