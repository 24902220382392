import React from 'react';

class ComingSoon extends React.Component {
  render() {
    return (
        <h1>Coming Soon!</h1>
    );
  }
}

export default ComingSoon;

        