import React from 'react';
import {Link} from 'react-router-dom';
import {AppBar, Typography, Grid} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
import links from '../configuration/routes';

class Footer extends React.Component {
    render() {
        const {classes} = this.props;
        return (
            <AppBar position={'static'} color={'primary'} className={classes.footer}>
                <Grid container spacing={32} justify={'center'}>
                    {links.map((section) => 
                        <Grid key={`section_${section.title}`} item xs={12} sm={4} md={3} lg={2} style={{color: 'white'}}>
                            <Typography variant="h6" color={'inherit'} className={classes.section}>{section.title}</Typography>
                            <Typography variant={'body2'}>
                                {section.data.map((link, ind) => 
                                    link.url.match(/^http/ig) ? <a key={`link_${ind}_${link.url}`} href={link.url} className={classes.link}>{link.label}</a>
                                    : <Link key={`link_${ind}_${link.url}`} to={link.url} className={classes.link}>{link.label}</Link>
                                )}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                <Typography variant={'caption'} color={'inherit'} style={{textAlign: 'center', marginTop: 100}}>Copyright © 2010-2018 Guns Priced Right</Typography>
            </AppBar>
        );
    }
}


const styles = (theme) => ({
    footer:{
        padding: '25px 50px',
        color: 'white',
        backgroundImage: 'url("bg-light.svg")',
        backgroundSize: '5%'
    },
    section:{
        fontWeight: 800, 
        textAlign: 'center'
    },
    link:{
        display: 'block',
        color: 'white',
        textDecoration: 'none',
        fontWeight: 300,
        textAlign: 'center',
        '&:hover':{
            fontWeight: 600
        }
    }
});

export default withStyles(styles)(Footer);
