import React from 'react';
import { Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

class ProductDetails extends React.Component {
    constructor(props){
        super(props);
        this.container = React.createRef();
        this.state={
            expanded: false
        }
    }
    formatLabel(label){
        if(label === 'msrp' || label === 'sku'){return label.toUpperCase()}
        else{
            let ind = label.indexOf('_');
            if(ind < 0){ return label.charAt(0).toUpperCase() + label.slice(1)}
            return label.charAt(0).toUpperCase() + label.slice(1, ind) + ' ' + label.charAt(ind+1).toUpperCase() + label.slice(ind+2);
        }
    }
    render() {
        const {gun} = this.props;
        const {classes}  = this.props;
        const details = Object.keys(gun).filter((key) => {
            return (
                key !== 'id' && 
                key !== 'type' &&
                key !== 'image' &&
                key !== 'name' &&
                key !== 'status' &&
                key !== 'price'
            );
        })
        return (
            <React.Fragment>
                <div className={classes.details}>
                    <div className={classes.wrapper + ' ' + (this.state.expanded ? 'open' : '')}>
                        {details.map((metric) => 
                            <Typography key={metric} color={'primary'} className={classes.feature}>
                                {`${this.formatLabel(metric)}: `}
                                <span style={{fontWeight: 800}}>
                                    {metric === 'msrp' ? '$' :''}{gun[metric]}
                                </span>
                            </Typography>
                        )}
                    </div>
                    <Button className={classes.toggle}  ref={this.container}
                        variant={'contained'} 
                        color={'primary'} 
                        onClick={() => {
                            this.setState({expanded: !this.state.expanded})
                        }}
                    >
                        {this.state.expanded ? 'Hide Details' : 'Show Details'}
                    </Button>
                </div>  
            </React.Fragment>
            
        );
    }
}
ProductDetails.propTypes = {
    details: PropTypes.object
}

const styles = (theme) => ({
    details:{
        flex: '1 1 0px', 
        maxWidth: '100%',
        marginLeft: 20,
        [theme.breakpoints.down('sm')]:{
            marginLeft: 0,
            marginTop: 20
        }
    },
    feature:{
        fontWeight: 300, 
        textIndent: '-1em', 
        marginLeft: '1em'
    },
    wrapper:{
        [theme.breakpoints.down('sm')]:{
            marginBottom: 20,
            display: 'none',
            '&.open':{
                display: 'block'
            }
        }
    },
    toggle:{
        display: 'none',
        backgroundColor: theme.palette.primary['50'],
        color: theme.palette.primary['500'],
        borderRadius: 50,
        width: 325,
        maxWidth: '100%',
        '&:hover':{
            backgroundColor: theme.palette.primary['200']
        },
        [theme.breakpoints.down('sm')]:{
            display: 'inline-block'
        }
    }
})
export default withStyles(styles)(ProductDetails);