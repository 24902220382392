import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles'

class Staff extends React.Component {
    render() {
        const {classes, name, job, image} = this.props;
        return (
            <div style={{margin: 10}}>
                <div className={classes.image} style={{backgroundImage: "url("+image+")"}}/>
                <Typography variant={'button'} color={'primary'} align={'center'} style={{fontSize: '1rem', lineHeight: 1, marginTop: 10}}>{name}</Typography>
                <Typography variant={'caption'} color={'primary'} align={'center'}>{job}</Typography>
            </div>
            
        );
    }
}
Staff.propTypes = {
    name: PropTypes.string.isRequired,
    job: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired
}

const styles = (theme) => ({
    image:{
        height: 140, 
        width: 140, 
        borderRadius: '50%', 
        background: 'blue',
        backgroundPosition: 'center',
        backgroundSize: '150%'
    }
})
export default withStyles(styles)(Staff);