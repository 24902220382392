import React from 'react';
import Product from '../components/Product';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';
import Filter from  '@material-ui/icons/FilterList';

import FilterDrawer from '../components/FilterDrawer';
import {withStyles} from '@material-ui/core/styles';

const list = require('../db.json');

class ForSale extends React.Component {
  constructor(props){
    super(props);
    this.state={
      fixedFilter: true,
      filter: false,
      expanded: null,
      filters:{
        type: this.props.match.params.search ? [this.props.match.params.search]: [],
        brand: [],
        caliber: [],
        price: [],
        action: [],
        capacity: []
      },
      activeFilters: this.props.match.params.search ? 1 : 0
    }
    this.container = React.createRef();
    this._handleScroll = this.handleScroll.bind(this);
    window.addEventListener('scroll', this._handleScroll, { passive: true })
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this._handleScroll, { passive: true })
  }

  handleScroll(event){
    let cont = this.container.current;
    if(cont.offsetTop+cont.offsetHeight - window.scrollY < window.innerHeight){
      this.setState({fixedFilter: false});
    }
    else{
      this.setState({fixedFilter: true});
    }
  }

  filterProducts(products){
    products = products.filter((gun) => {
      let fail = 0;
      Object.keys(this.state.filters).forEach((filter) => {
        if(this.state.filters[filter].length > 0 && !this.state.filters[filter].includes(gun[filter])){
          fail++;
        }
      })
      return fail === 0;
    })
    return products;
  }

  updateFilter(type, value){
    let filters = {...this.state.filters};
    let change = 0;
    if(filters[type].includes(value)){
      filters[type] = filters[type].filter((item) => item !== value);
      change = -1;
    }
    else{
      filters[type].push(value);
      change = 1;
    }
    this.setState({filters: filters, activeFilters: this.state.activeFilters + change});
    window.scrollTo(0,0);
  }

  clearFilters(){
    let empty = {
      type: [],
      brand: [],
      caliber: [],
      price: [],
      action: [],
      capacity: []
    };

    this.setState({filters: empty, activeFilters: 0});
  }

  render() {
    const {classes} = this.props;
    let filteredList = this.filterProducts(list.guns);
    return (
        <React.Fragment>
          <div ref={this.container} className={classes.container}>
            {filteredList.map((gun, ind) => (
                <React.Fragment key={ind}>
                    <Product gun={gun}/>
                    {ind < list.guns.length-1 && <Divider/>}
                </React.Fragment>
            ))}
            {filteredList.length === 0 &&
              <Typography variant={'h6'} color={'primary'} style={{textAlign: 'center', paddingTop: 25}}>0 Results - adjust your filters</Typography>
            }
            <AppBar className={classes.filterBar} elevation={0} style={{position: this.state.fixedFilter ? 'fixed' : 'absolute'}}>
              <Button
                variant={'contained'} 
                color={'primary'} 
                style={{maxWidth: '100%'}}
                onClick={() => this.setState({filter: true})}
              >
                <Filter style={{marginRight: 5}}/>
                {'Filter' + (this.state.activeFilters > 0 ? ' (' + this.state.activeFilters + ' selected)' : '')}
              </Button>
            </AppBar>
            
          </div>
          <FilterDrawer 
            open={this.state.filter} 
            update={(filter, value) => this.updateFilter(filter, value)} 
            clear={() => this.clearFilters()}
            onClose={() => this.setState({filter: false, expanded: null})} 
            filters={this.state.filters} 
            activeFilters={this.state.activeFilters} 
          />
        </React.Fragment>
    );
  }
}
const styles = (theme) => ({
  filterBar:{
    top: 'inherit',
    bottom: 0,
    padding: 20,
    alignItems: 'center',
    background: 'rgba(255,255,255,0.75)',
    zIndex: 0
  },
  container: {
    position: 'relative',
    paddingBottom: 76,
    [theme.breakpoints.down('sm')]:{
      textAlign: 'center'
    }
  }
})
export default withStyles(styles)(ForSale);

        