import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import BannerNav from './navigation/BannerNavigation';
import AppRoutes from './navigation/AppRoutes';
import { BrowserRouter as Router } from 'react-router-dom';

import * as serviceWorker from './serviceWorker';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
require('typeface-yantramanav');

const purple = {
    50: '#e8e8f0',
    100: '#c5c6db',
    200: '#9ea0c3',
    300: '#777aaa',
    400: '#595e98',
    500: '#3c4186',
    600: '#363b7e',
    700: '#2e3273',
    800: '#272a69',
    900: '#1a1c56',
    A100: '#9498ff',
    A200: '#6167ff',
    A400: '#2e35ff',
    A700: '#141dff',
    'contrastDefaultColor': 'light',
};
const yellow = {
    50: '#fdfae3',
    100: '#f9f2b9',
    200: '#f5e98b',
    300: '#f1e05d',
    400: '#eeda3a',
    500: '#ebd317',
    600: '#e9ce14',
    700: '#e5c811',
    800: '#e2c20d',
    900: '#ddb707',
    A100: '#ffffff',
    A200: '#fff6d3',
    A400: '#ffeca0',
    A700: '#ffe786',
    'contrastDefaultColor': 'dark',
};
const theme = {
    typography:{
        useNextVariants: true,
        fontFamily: 'Yantramanav, Futura, "Open Sans", Helvetica, Roboto, sans-serif'
    },
    palette:{
        primary: purple,
        secondary: yellow
    },
    breakpoints:{
        values:{
            xs: 0,
            sm: 420,
            md: 640,
            lg: 960,
            xl: 1280
        }
    }
}

ReactDOM.render(
    <MuiThemeProvider theme={createMuiTheme(theme)}>
        <Router>
            <BannerNav>
                <AppRoutes/>
            </BannerNav>
        </Router>
    </MuiThemeProvider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();