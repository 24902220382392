import React from 'react';
import {withRouter} from 'react-router-dom';
import {AppBar, Tabs, Tab, Toolbar, Typography} from '@material-ui/core';
import Footer from './Footer';
import { withStyles } from '@material-ui/core/styles'
import BannerStyles from './BannerStyles';

const CONFIG ={
  HEADER_HEIGHT: 60,
  HEADER_PADDING: 10,
  MINI_LOGO_HEIGHT: 20,
  BANNER_HEIGHT: 225
}

class BannerNavigation extends React.Component {
  constructor(props){
    super(props);
    this.tabNav = React.createRef();
    //this.body = React.createRef();
    this.state={
      stickyTabs: false
    }
    props.history.listen((location, action) => {
      window.scrollTo(0,window.scrollY > 225 ? 225 : window.scrollY);
    });
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this), { passive: true })
    // this.body.current.addEventListener('scroll', this.handleScroll.bind(this), { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll.bind(this))
    // this.body.current.removeEventListener('scroll', this.handleScroll.bind(this))
  }

  handleScroll(event) {
    if(!this.tabNav || !this.tabNav.current){ return; }
    // this.setState({stickyTabs: this.body.current.scrollTop >= this.tabNav.current.offsetTop-CONFIG.HEADER_HEIGHT})
    this.setState({stickyTabs: window.scrollY >= this.tabNav.current.offsetTop-CONFIG.HEADER_HEIGHT})
  }

  currentRoute(loc){
    if(loc === '/'){return loc;}
    if(loc.match(/^\/forsale/ig)){return '/forsale';}
    if(loc.match(/^\/services/ig)){return '/services';}
    return false;
  }

  render() {
    const {classes} = this.props;
    const tabClasses = {selected: classes.activeTab, root: classes.tab, wrapper: classes.tabContent}
    return (
      <div /*ref={this.body}*/ className={classes.container}>
        <AppBar position={'fixed'} className={classes.header}>
          <img alt="GPR Logo" src={'/gprlogo-small.svg'} className={classes.miniLogo} />
          <Typography variant="caption" color="inherit">100 Bettis Road Dravosburg, PA | 412-466-5199</Typography>
        </AppBar>
        <AppBar position={'static'} color={'default'} style={{marginTop: CONFIG.HEADER_HEIGHT}}>
          <Toolbar className={classes.banner}>
              <div className={classes.lighten}/>
              <img alt="GPR Logo" src={'/gprlogo.svg'} className={classes.bigLogo} />
          </Toolbar>
        </AppBar>
        <div ref={this.tabNav}>
            <AppBar 
              color={'primary'} 
              position={this.state.stickyTabs ? 'fixed' : "static"} 
              style={{
                marginTop: this.state.stickyTabs ? CONFIG.HEADER_HEIGHT : 0
              }}
              className={classes.tabBar}
            >
              <Tabs textColor={'inherit'} 
                TabIndicatorProps={{
                  style:{
                    background: 'transparent'
                  }
                }} 
                value={this.currentRoute(this.props.location.pathname)} 
                centered 
                onChange={(evt, value) => {
                  this.props.history.push(value); 
                  // this.body.current.scrollTo(0,this.body.current.scrollTop > 225 ? 225 : this.body.current.scrollTop);
                  // window.scrollTo(0,window.scrollY > 225 ? 225 : window.scrollY);
                }}
              >
                <Tab classes={tabClasses} label="About" value={'/'}/>
                <Tab classes={tabClasses} label="For Sale" value={'/forsale'}/>
                <Tab classes={tabClasses} label="Services" value={'/services'}/>
              </Tabs>
            </AppBar>
        </div>
        <div style={{marginTop: this.state.stickyTabs ? '48px' : 0}}>
          {this.props.children}
        </div>
        <Footer/>
      </div>  
    );
  }
}




export default withStyles(BannerStyles(CONFIG))(withRouter(BannerNavigation));
