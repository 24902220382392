import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import Typography from '@material-ui/core/Typography';

import Close from '@material-ui/icons/CheckCircleOutlined';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';

import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const list = require('../db.json');

const capitalize = (str) => {
  if(str === null || (typeof str) !== 'string' || str.length < 1){return str;}
  return str.charAt(0).toUpperCase() + str.slice(1);
}

class FilterDrawer extends React.Component {
  constructor(props){
    super(props);
    this.state={
      expanded: null
    }
    this.filters = {
        type: [],
        brand: [],
        caliber: [],
        price: [],
        action: [],
        capacity: []
      };
      this.populateFilters();
  }

  populateFilters(){
    list.guns.forEach((gun) => {
      if(!this.filters.type.includes(gun.type)){this.filters.type.push(gun.type)}
      if(!this.filters.brand.includes(gun.brand)){this.filters.brand.push(gun.brand)}
      if(!this.filters.caliber.includes(gun.caliber)){this.filters.caliber.push(gun.caliber)}
      if(!this.filters.price.includes(gun.price)){this.filters.price.push(gun.price)}
      if(!this.filters.action.includes(gun.action)){this.filters.action.push(gun.action)}
      if(!this.filters.capacity.includes(gun.capacity)){this.filters.capacity.push(gun.capacity)}
    })
    this.filters.type.sort();
    this.filters.brand.sort();
    this.filters.caliber.sort();
    this.filters.price.sort();
    this.filters.action.sort();
    this.filters.capacity.sort((a,b) => {
      a = a.split('+');
      b = b.split('+');
      if(a[0] === b[0]){
        if(a.length > 1){
          if(b.length > 1){
            return (a[1] - b[1]);
          }
          return 1;
        }
        if(b.length >1){
          return -1;
        }
      }
      return (parseInt(a[0],10) - parseInt(b[0]));
    });
  }

  expandFilter(filter){
    if(this.state.expanded === filter){
      this.setState({expanded: null});
    }
    else{
      this.setState({expanded: filter});
    }
  }

  render() {
    const {classes} = this.props;
    return (
        <Drawer PaperProps={{className: classes.paper}} open={this.props.open} onClose={() => this.props.onClose()}>
            <AppBar className={classes.header} position={'static'} style={{flex: '0 0 auto'}}>
                <Typography variant={'subtitle1'} color={'inherit'}>{`Filter Results`}</Typography>
                {this.props.activeFilters > 0 && <Typography variant={'subtitle2'} color={'inherit'} style={{cursor: 'pointer'}} onClick={() => this.props.clear()}>Clear All</Typography>}
            </AppBar>
            <List className={classes.filterList}>
            {Object.keys(this.props.filters).map((filter, ind) => (
                <React.Fragment key={filter}>
                <ListItem divider button onClick={() => this.expandFilter(filter)} className={classes.filter} style={{marginTop: this.state.expanded === filter && ind !== 0 ? 20 : 0}}>
                    <ListItemIcon>
                        {this.state.expanded === filter ? <Remove/> : <Add/>}
                    </ListItemIcon>
                    <ListItemText style={{padding: 0}} primary={capitalize(filter) + (this.props.filters[filter].length > 0 ? ` (${this.props.filters[filter].length})` : '')} />
                </ListItem>
                <Collapse in={this.state.expanded === filter} unmountOnExit style={{transitionProperty: 'all', marginBottom: this.state.expanded === filter ? 20 : 0}}>
                    <List component="div" disablePadding>
                    {this.filters[filter].map((value) => (
                        <ListItem key={value} button onClick={() => this.props.update(filter, value)}>
                        <ListItemText primary={capitalize(value)} />
                        {this.props.filters[filter].includes(value) ? <Close color={'primary'}/> : null}
                        </ListItem>
                    ))}
                    </List>
                </Collapse>
                </React.Fragment>
            ))}
            </List>
        </Drawer>
    );
  }
}
FilterDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    activeFilters: PropTypes.number.isRequired,
    filters: PropTypes.object.isRequired
}
const styles = (theme) => ({
    paper:{
        width: 250, 
        maxWidth: '90%', 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        '&:focus':{
            outline: 'none'
        }
    },
    filterList:{
        flex: '1 1 0px', 
        overflowY: 'auto'
    },
    filter:{
        transition: 'margin-top 300ms linear', 
        background: '#f2f2f2'
    },
    header:{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 20px',
        color: 'white',
        backgroundColor: theme.palette.primary['500'],
        backgroundSize: '25%',
        backgroundImage: 'url(/bg-light.svg)',
        height: 60
    }
})
export default withStyles(styles)(FilterDrawer);

        