import React from 'react';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';

import Staff from '../components/Staff';

class About extends React.Component {
  render() {
    const {classes} = this.props;
    return (
        <div style={{padding: 40, maxWidth: 600, margin: '0px auto'}}>
          <Typography variant={'h6'} color={'primary'} paragraph className={classes.title}>Who we are</Typography>
            <Typography variant={'body1'} color={'primary'} className={classes.paragraph} paragraph>Guns Priced Right is the answer for people looking for a square deal when they purchase or sell a firearm or need help figuring out how much their gun is worth.</Typography>
          
          <Typography variant={'h6'} color={'primary'} paragraph className={classes.title}>What we do</Typography>
            <Typography variant={'body1'} color={'primary'} paragraph className={classes.paragraph}>Whether you want to buy, sell, or trade, we endeavor to make your experience smooth and stress free.</Typography>
            <Typography variant={'body1'} color={'primary'} paragraph className={classes.paragraph}>If you are looking for a gun for personal protection, hunting, or a service piece, we will work with you to find exactly what you need. If we don’t have what you’re looking for in stock, we will compare all of our wholesalers to find you the best possible price available.</Typography>
            <Typography variant={'body1'} color={'primary'} paragraph className={classes.paragraph}>Selling your gun? We buy used guns and also accept guns for resale on consignment. Stop in with your gun and we’ll tell you how much we would sell it for.</Typography>
          
          <Typography variant={'h6'} color={'primary'} paragraph className={classes.title}>Our staff</Typography>
            <Typography variant={'body1'} color={'primary'} className={classes.paragraph} paragraph>Our experienced staff are here to help. Combined, we have over 40+ years of firearms experience and will do everything we can to get you what you need.</Typography>
          
          <div className={classes.staffWrapper}>
            <Staff name={'Victor V.'} job={'Owner'} image={'./staff.jpg'}/>
            <Staff name={'Winston W.'} job={'Sales Lead'} image={'./staff.jpg'}/>
            <Staff name={'George G.'} job={'Sales Associate'} image={'./staff.jpg'}/>
            <Staff name={'Jeremy J.'} job={'Webmaster'} image={'./staff.jpg'}/>
            <Staff name={'Colin C.'} job={'Accounting'} image={'./staff.jpg'}/>
          </div>
        </div>
    );
  }
}

const styles = (themes) => ({
  staffWrapper:{
    display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', margin: 10
  },
  paragraph:{
    fontWeight: 300,
    lineHeight: 1.5
  }
});
export default withStyles(styles)(About);

        