import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles'

class PromoGraphic extends React.Component {
    getBadgeColor(status){
        switch(status){
            case 'new':
                return {bg: '#60e273', fg: 'white'}; // green
            case 'used':
                return {bg: '#737be8', fg: 'white'}; // purple
            case 'sale':
                //return {bg: '#3dc7e6', fg: 'white'}; // blue
                return {bg: 'orange', fg: 'white'}; // blue
            case 'in-stock':
            case 'sold':
            default:
                return {bg: '#f11300', fg: 'white'}; // red
        }
    }
    render() {
        const {classes, picture, title, price, status} = this.props;
        const badge = this.getBadgeColor(status);
        return (
            <div className={classes.wrapper}>
                <img alt={title} src={picture} width={'100%'} height={'auto'} style={{display: 'block'}}/>
                {status && status !== 'in-stock' && 
                    <Typography variant={'h6'} className={classes.badge} style={{background: badge.bg, color: badge.fg}}>{status.toUpperCase()}</Typography>
                }
                <Typography variant={'h6'} color={'primary'} className={classes.title}>{title}</Typography>
                <Typography variant={'subtitle1'} color={'primary'} style={{lineHeight: 1}}>{`$${price}`}</Typography>
            </div>
        );
    }
}
PromoGraphic.propTypes = {
    picture: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['new', 'used', 'sale', 'in-stock', 'sold']),
    title: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired
}

const styles = (theme) => ({
    wrapper:{
        textAlign: 'center', 
        position: 'relative', 
        width: 325, 
        maxWidth: '100%'
    },
    badge:{
        position: 'absolute',
        top: 10,
        right: 10,
        borderRadius: 50,
        padding: '0px 20px',
        color: 'white',
        opacity: 0.85,
        textShadow: '1px 1px rgba(0,0,0,.95)'
    },
    title:{
        lineHeight: 1, 
        marginTop: '1rem', 
        marginBottom: '0.5rem'
    }
})
export default withStyles(styles)(PromoGraphic);