const BannerStyles = (config) => {

return (theme) => ({
    container:{
      //height: '100%',
      //overflowX: 'hidden',
      //overflowY: 'auto'
    },
    header:{
      backgroundColor: 'rgba(0,0,0,0.95)', 
      height: config.HEADER_HEIGHT, 
      alignItems: 'center', 
      justifyContent: 'center', 
      padding: config.HEADER_PADDING
    },
    miniLogo:{
        height: config.MINI_LOGO_HEIGHT,
        width: 'auto',
        maxWidth: '100%'
    },
    banner:{
        position: 'relative', 
        height: config.BANNER_HEIGHT, 
        padding: config.HEADER_PADDING, 
        justifyContent: 'center', 
        backgroundImage: "url('/store.jpg')", 
        backgroundRepeat: 'no-repeat', 
        backgroundSize: 'cover', 
        backgroundPosition:'center'
    },
    bigLogo:{
        height: '85%',
        width: 'auto',
        maxWidth: '100%', 
        zIndex: 100
    },
    lighten:{
        position: 'absolute', 
        height: '100%', 
        width: '100%', 
        background: 'white', 
        opacity: 0.2, 
        zIndex: 0
    },
    tabBar:{
        color: 'white',
        backgroundImage: 'url("/bg-light.svg")',
        backgroundSize: '10%'
    },
    tab:{
        transform: 'skew(-23deg)',
        opacity: 1
    },
    tabContent:{
        transform: 'skew(23deg)'
    },
    activeTab: {
        background: 'white',
        color: theme.palette.primary['500']
    }
  })
}
export default BannerStyles;