export const links = [
    {
        title: "For Sale",
        data:[
            {label: 'Pistols', url: '/forsale/pistols'},
            {label: 'Revolvers', url: '/forsale/revolvers'},
            {label: 'Shotguns / Rifles', url: '/forsale/long-guns'},
            {label: 'Store Policies', url: '/policies'}
        ]
    },
    {
        title: "Services",
        data:[
            {label: 'Sales', url: '/forsale'},
            {label: 'Transfers', url: '/services/transfers'},
            {label: 'Consignment', url: '/services/consignment'},
            {label: 'Photography', url: '/services/photography'},
            {label: 'Special Orders', url: '/services/special-orders'}
        ]
    },
    {
        title: "News",
        data:[
            {label: 'Pending Legislation', url: '/legislation'},
            {label: 'PA Firearms News', url: '/pa-news'},
            {label: 'National Firearms News', url: '/national-news'}
        ]
    },
    {
        title: "Merchandise",
        data:[
            {label: 'Civ Div Designs', url: 'https://www.civdivdesigns.com/'},
            {label: 'Lights and Holsters Tactical Store', url: 'http://www.lightsnholsters.com/'},
        ]
    },
    {
        title: "Resources",
        data:[
            {label: 'Join the NRA', url: 'https://membership.nrahq.org/forms/signup.asp?campaignid=XS024537'},
            {label: 'PA Gun Rights Flyer', url: 'http://www.frontlinearmory.com/misc/paoc/PennsylvaniaGunRights.pdf'},
            {label: 'PA Gun Laws', url: 'https://www.nraila.org/gun-laws/state-gun-laws/pennsylvania.aspx'},
            {label: 'Concealed Carry', url: 'https://www.usacarry.com/concealed-carry-resources.html'},
            {label: 'Big List of All PA Gun Shows', url: 'https://www.gunshowminute.com/gunshows/pennsylvania/'},
            {label: '"No Guns, No Money" Business Cards', url: 'http://www.learntocarry.com/nogunsnomoney/'}
        ]
    },

    {
        title: "Organizations",
        data:[
            {label: 'PA Firearms Association', url: 'http://www.pafoa.org/'},
            {label: 'Firearms Owners Against Crime', url: 'https://foac-pac.org/'},
            {label: 'Gun Owners of America', url: 'https://gunowners.org/'},
            {label: 'Second Amendment Foundation', url: 'https://www.saf.org/'},
            {label: 'Keep and Bear Arms', url: 'http://www.keepandbeararms.com/'},
            {label: 'USA Concealed Carry', url: 'https://www.usacarry.com/'},
            {label: 'Women and Guns Magazine', url: 'https://www.womenandguns.com/'}
        ]
    },
]
export default links;